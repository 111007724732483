<template>
  <vx-card title="Route Assignment">
    <div class="vx-input-group flex">
      <vs-button
        v-if="hasPermission('create')"
        class="mt-2 mr-2"
        color="success"
        type="border"
        icon-pack="feather"
        icon="icon-plus"
        @click="handleCreate()"
        >Create</vs-button
      >
      <vs-button
        v-if="hasPermission('create')"
        class="mt-2 mr-2"
        color="success"
        type="border"
        icon-pack="feather"
        icon="icon-upload"
        @click="handleImport()"
        >Import</vs-button
      >
    </div>
    <br />
    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Territory</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <v-select
          multiple
          :filterable="true"
          :options="optionTerritory"
          v-model="selectedTerritory"
          placeholder="all"
        />
      </div>
    </div>
    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Salesman</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <v-select
          placeholder="all"
          multiple
          :filterable="false"
          :options="optionPersonal"
          v-model="selectedPersonal"
          @search="
            (search, loading) => {
              onSearchPersonal(search, loading);
            }
          "
        />
      </div>
    </div>
    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Product Team</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <v-select
          placeholder="all"
          multiple
          :filterable="false"
          :options="optionProductTeam"
          v-model="selectedProductTeam"
          @search="
            (search, loading) => {
              onSearchProductTeam(search, loading);
            }
          "
        />
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col w-full mb-base">
        <vs-tabs :value="active">
          <vs-tab label="Draft">
            <div class="tab-text">
              <data-table
                :territoryIDs="this.selectedTerritoryIDs"
                :personalIDs="this.selectedPersonalIDs"
                :productTeamIDs="this.selectedProductTeamIDs"
                :baseUrl="this.baseUrl"
                :dateNow="this.dateNow"
                status="Draft"
              ></data-table>
            </div>
          </vs-tab>
          <vs-tab label="Assigned">
            <div class="tab-text">
              <data-table
                :territoryIDs="this.selectedTerritoryIDs"
                :personalIDs="this.selectedPersonalIDs"
                :productTeamIDs="this.selectedProductTeamIDs"
                :baseUrl="this.baseUrl"
                :dateNow="this.dateNow"
                status="Assigned"
              ></data-table>
            </div>
          </vs-tab>
          <vs-tab label="Canceled">
            <div class="tab-text">
              <data-table
                :territoryIDs="this.selectedTerritoryIDs"
                :personalIDs="this.selectedPersonalIDs"
                :productTeamIDs="this.selectedProductTeamIDs"
                :baseUrl="this.baseUrl"
                :dateNow="this.dateNow"
                status="Canceled"
              ></data-table>
            </div>
          </vs-tab>
        </vs-tabs>
      </div>
    </div>
  </vx-card>
</template>
<script>
import DataTable from "./DataTable.vue";
import Datepicker from "vuejs-datepicker";

import _ from "lodash";
import vSelect from "vue-select";
export default {
  components: {
    Datepicker,
    DataTable,
    vSelect,
  },
  data() {
    return {
      detailShow: "core vx-col md:w-1/2 w-full mb-base ",
      detailHide: "core vx-col md:w-1/1 w-full mb-base ",
      detail: false,

      baseUrl: "/api/sfa/v1/route-assignment",
      optionTerritory: [],
      selectedTerritory: [],
      selectedTerritoryIDs: [],
      active: 0,
      dateNow: new Date(),
      optionPersonal: [],
      selectedPersonal: [],
      selectedPersonalIDs: [],
      optionProductTeam: [],
      selectedProductTeam: [],
      selectedProductTeamIDs: [],
    };
  },
  methods: {
    customLabelTerritory({ code, name }) {
      return `${code} ${name}`;
    },
    handleCreate() {
      this.$router.push({
        name: "route-assignment-create",
        // params: { id: id },
      });
    },
    handleImport() {
      this.$router.push({
        name: "route-assignment-import",
        // params: { id: id },
      });
    },
    handleOpen() {
      this.detail = true;
    },
    getTerritory() {
      this.$vs.loading();
      this.$http
        .get(this.baseUrl + "/territory", {
          params: {
            length: 0,
            order: "name",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              resp.data.records.map(function (x) {
                return (x.label = x.code + " " + x.name);
              });

              this.optionTerritory = resp.data.records;
            } else {
              this.optionTerritory = [];
              this.selectedTerritory = [];
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    onSearchPersonal(search, loading) {
      if (search.length) {
        loading(true);
        this.searchPersonal(loading, search, this);
      }
    },
    searchPersonal: _.debounce((loading, search, t) => {
      var territorryIDs = [];

      t.selectedTerritory.forEach((element) => {
        territorryIDs.push(element.id);
      });

      t.$http
        .get(t.baseUrl + "/sales2", {
          params: {
            length: 100,
            search: search.trim(),
            territory_ids: territorryIDs,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              t.optionPersonal = resp.data.records;
            } else {
              t.optionPersonal = [];
            }
            loading(false);
          } else {
            t.optionPersonal = [];
            loading(false);
          }
        });
    }, 350),
    onSearchProductTeam(search, loading) {
      if (search.length) {
        loading(true);
        this.searcProductTeam(loading, search, this);
      }
    },
    searcProductTeam: _.debounce((loading, search, t) => {
      t.$http
        .get(t.baseUrl + "/product-team", {
          params: {
            length: 100,
            search: search.trim(),
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              resp.data.records.map(function (x) {
                return (x.label = x.code + " " + x.name);
              });
              t.optionProductTeam = resp.data.records;
            } else {
              t.optionProductTeam = [];
            }
            loading(false);
          } else {
            t.optionProductTeam = [];
            loading(false);
          }
        });
    }, 350),
  },
  mounted() {
    this.$store.dispatch("user/getPermissions", "master-route-plan");
    this.getTerritory();
  },
  watch: {
    selectedTerritory(val) {
      var selectedTerritoryIDs = [];

      val.forEach((element) => {
        selectedTerritoryIDs.push(element.id);
      });
      this.selectedTerritoryIDs = selectedTerritoryIDs;

      this.optionPersonal = [];
      var indexSelectedPersonalToRemove = [];

      this.selectedPersonal.forEach((personal, indexPersonal) => {
        var territorryIDs = JSON.parse(personal.territory_ids);

        let cek = 0;
        selectedTerritoryIDs.forEach((tid) => {
          if (!territorryIDs.includes(val.id)) {
            cek++;
          }
        });

        if (cek == 0) {
          if (!indexSelectedPersonalToRemove.includes(indexPersonal)) {
            indexSelectedPersonalToRemove.push(indexPersonal);
          }
        }
      });

      indexSelectedPersonalToRemove.forEach((val) => {
        this.selectedPersonal.splice(val, 1);
      });
    },

    selectedPersonal(val) {
      var ids = [];

      val.forEach((element) => {
        ids.push(element.personal_id);
      });
      this.selectedPersonalIDs = ids;
    },
    selectedProductTeam(val) {
      var ids = [];

      val.forEach((element) => {
        ids.push(element.id);
      });
      this.selectedProductTeamIDs = ids;
    },
  },
};
</script>

<style scoped>
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}
</style>
